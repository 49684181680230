// Importar la clase CopyToClipboard
import CopyToClipboard from './CopyToClipboard';

// agrega un listener al contenido cuando se carga el DOM...
document.addEventListener('DOMContentLoaded', function() {
    // ... e inicializa los botones
    const copyInstance = new CopyToClipboard();
});

export default CopyToClipboard;

// // Función para inicializar botones manualmente
// window.nucleus = {
//     copyToClipboard: function(selector) {
//         const copyInstance = new CopyToClipboard(selector);
//         copyInstance.initButtons();
//     }
// };
