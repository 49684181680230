import $ from 'jquery';

$('.open-ofc-light-sheet').click(function() {
    var rowId = $(this).data('row-id');
    $("table tr").removeClass("table-row-sheet-active");
    var tableRow = $('#' + rowId);
    tableRow.addClass('table-row-sheet-active');
    var offCanvasContent = $('#cow-data-sheet');
    console.log(offCanvasContent, tableRow.data('light-sheet'));


    // $('.table-row[id="' + rowId + '"]').addClass('table-secondary');

    offCanvasContent.load(tableRow.data('light-sheet'), function() {});
    // offCanvasContent.load(tableRow.data('light-sheet'), function() {
    // //     // $('#offcanvas').addClass('show');
    // //     // $('body').addClass('offcanvas-open');
    // //     console.log('rowId', rowId);
    // //     $('.table-row[id="' + rowId + '"]').addClass('table-active');
    // });
});