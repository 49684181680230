import BaseComponent from "../BaseComponent";

/**
 * Clase SwapContent para seleccionar un elemento y mostrar su contenido
 * cargado desde una URL.
 *
 * ES6 Module
 *
 * @category   Nucleus
 * @package    Mixins
 * @author     Miguel Valencia @mavo <nova_mig@hotmail.com>
 */

class SwapContent extends BaseComponent {
    triggerSelector = 'swap-content';

    spinnerTemplate = '#spinner-tpl';

    constructor(selector = null) {
        // Inicializa la clase padre
        super();
        // Si se ha pasado un selector, se inicializa el botón manualmente...
        this.selectElements(selector);
        // Inicializa los botones
        this.init();

    }
    /**
     * Inicializa los botones con atributo data-nc-trigger="copy".
     *
     * @returns {void}
     */
    init() {
        // Si se ha pasado un selector...
        if (this.element) {
            // ... se inicializa el botón con ese selector
            this.startComponent(this.element);
        } else {
            // ... si no se ha pasado un selector, se inicializan todos los botones
            this.elements.forEach(element => this.startComponent(element));
        }
    }

    startComponent(element) {
        element.addEventListener("click", () => {
            // Obtiene el elemento donde se mostrará el contenido
            let $target = element.dataset.ncTarget;
            // Obtiene la URL de donde se cargará el contenido
            let $url = element.dataset.ncUrl;
            // Carga el spinner para mostrar que se está cargando el contenido
            this.loadSpinner($target);

            // Realiza una petición AJAX para obtener el contenido de la URL
            fetch($url)
                .then(response => response.text())  // Obtiene el contenido como texto
                .then(data => {
                    // Obtiene el elemento de cuerpo del offcanvas
                    let offcanvasBody = document.querySelector($target);
                    // Coloca el contenido obtenido en el cuerpo del offcanvas
                    offcanvasBody.innerHTML = data;
                    // Se añade nuevo código para convertir en un componente de frecuencia si existe en la respuesta.
                    const parser = new DOMParser();
                    // Se convierte de txt a html para buscar el componente
                    const dataHtml = parser.parseFromString(data, 'text/html');
                    // En el dataHtml buscar que que tenga el data set component="frequency-component"
                    const frequencyComponent = dataHtml.querySelector('[component="frequency-component"]');

                    // Si existe el componente obtener todo su data set
                    if (frequencyComponent) {
                        // Obtener el data set
                        const data = {
                            // Id del elemento que contiene el componente
                            id: frequencyComponent.dataset.id,
                            // Texto por defecto a mostrar en los selects de frecuencia
                            defaultOptionText: frequencyComponent.dataset.defaultOptionText,
                            // Título por defecto a mostrar en el componente
                            title: frequencyComponent.dataset.title,
                            // Nombre del componente
                            name: frequencyComponent.dataset.name,
                            // Clase que se pondrá al componente
                            classInput: frequencyComponent.dataset.classInput,
                            // Lenguaje del componente para mostrar los textos
                            language: frequencyComponent.dataset.language,
                            // Label del componente
                            label: frequencyComponent.dataset.label,
                            // Clase que se pondrá al label del componente
                            classLabel: frequencyComponent.dataset.classLabel,
                            // Mensaje que se mostrará al final del componente
                            message: frequencyComponent.dataset.message,
                            // Clase que se pondrá al mensaje del componente
                            classMessage: frequencyComponent.dataset.classMessage,
                            // Estado del componente
                            status: frequencyComponent.dataset.status,
                            // Elemento Hidden que tendrá el JSON con los datos del componente
                            hidden: frequencyComponent.dataset.hidden,
                            // Botón que se mostrará al final del componente
                            button: frequencyComponent.dataset.button,
                            // Opciones que se mostrarán en el primer select y el resto de selects en base a la opción seleccionada
                            options: frequencyComponent.dataset.options ? JSON.parse(frequencyComponent.dataset.options) : null,
                            // Opción para el ending del componente
                            ending: frequencyComponent.dataset.ending ? JSON.parse(frequencyComponent.dataset.ending) : null,
                            // Valores que se enviarán en el hidden del componente
                            hiddenValue: frequencyComponent.dataset.hiddenValue ? JSON.parse(frequencyComponent.dataset.hiddenValue) : null,
                            // Id del elemento que se mostrará el componente si es externo
                            externalIdShow: frequencyComponent.dataset.externalIdShow,
                            // Id del componente padre donde se va a empezar a renderizar el componente
                            parentId: frequencyComponent.dataset.parentId,
                        };
                        const frequencyComponentWithData = new window.FrequencyComponent(frequencyComponent.id, data);
                        frequencyComponentWithData.init();
                    }

                    // Ejecuta el código personalizado enviado en el atributo data-nc-function
                    this.execCustomCode(element);
                })
                .catch(error => {
                    console.error("Error al cargar contenido:", error);
                });
        });
    }

    /**
     * Ejecuta el código personalizado enviado en el atributo data-nc-function.
     *
     * @param object $element
     */
    execCustomCode($element) {
        // Verifica si hay una función personalizada a ejecutar  (ABEL)
        let customFunction = $element.dataset.ncFunction;

        // Verifica si hay una función personalizada o código js a ejecutar
        if (customFunction) {
            // Ejecuta la función personalizada
            eval(customFunction);
        }
    }

    loadSpinner($target)
    {
        // Obtener el contenido del template
        const $template = document.querySelector(this.spinnerTemplate);
        // Clonar el contenido del template
        const $content = $template.content.cloneNode(true);
        // Obtener el elemento donde deseas mostrar el template
        let $container = document.querySelector($target);
        // vaciar el contenedor
        $container.innerHTML = '';
        // Agregar el contenido clonado al contenedor
        $container.appendChild($content);
    }
}

// Exporta la clase para que esté disponible en el DOM
export default SwapContent;
