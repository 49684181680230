// Importa jQuery y lo expone como $ y jQuery para que sea accesible desde fuera de este paquete
import $jQuery from 'jquery';
window.jQuery = window.$ = $jQuery;
// Importa popper y lo expone como popper para que sea accesible desde fuera de este paquete
import $popper from '@popperjs/core';
window.popper = $popper;
// Importa bootstrap y lo expone como bootstrap para que sea accesible desde fuera de este paquete
import './bootstrap.js';
window.bootstrap = require('bootstrap');
// Importa moment y lo expone como moment para que sea accesible desde fuera de este paquete
import $moment from 'moment';
window.moment = $moment;
// Importa el archivo nucleus.js que contiene todos los componentes de Nucleus
import $nucleus from './nucleus/nucleus';
window.nucleus = $nucleus;

// COMPONENTS
require('./components/bootstrap-form-validate.js');
require('./components/opt.js');
require('./components/mtm-components/mtm.js');
// require('./components/bootstrap-table.js');



function copyToClipboard(element) {
    var $temp = $("<input>");

    $("body").append($temp);

    $temp.val($(element).text()).select();

    document.execCommand("copy");

    $temp.remove();
}

require('./ideasfarm/light-sheet.js');
require('./components/modal-delete.js')

// importa la librería de forms
let iforms = require('./libraries/forms.js');
// hace accesible la librería de forms fuera del paquete
window.iforms = iforms;

/*
|--------------------------------------------------------------------------
| Cierra collapse cuando se abre otro collapse
|--------------------------------------------------------------------------
*/
document.addEventListener('DOMContentLoaded', (event) => {
    // Encuentra todos los colapsos en la página
    let collapses = document.querySelectorAll('.collapse');

    collapses.forEach((collapse) => {
            // Determina el nivel del colapso basándose en su anidación en el DOM
        let level = 0;
        let parent = collapse.parentElement;
        while (parent && parent != document.body) {
            if (parent.classList.contains('collapse')) level++;
            parent = parent.parentElement;
        }

        collapse.dataset.level = level;

        collapse.addEventListener('show.bs.collapse', (event) => {
            // Evita la propagación del evento para prevenir que colapsos padres se cierren
            event.stopPropagation();

            // Obtiene todos los colapsos que no sean ancestros directos del colapso abierto y que sean de nivel igual o inferior
            let others = Array.from(document.querySelectorAll('.collapse.show')).filter((other) => {
                return !other.contains(collapse) && other.dataset.level >= level;
            });

            // Cierra todos los colapsos seleccionados
            others.forEach((other) => {
                let bsCollapse = new bootstrap.Collapse(other, { toggle: false });
                bsCollapse.hide();
            });
        });
    });
});

require('./libraries/prism.js');


