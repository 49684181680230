/**
 * Description: funciones para depuración y mostrar mensajes en la consola
 *
 * Contiene los métodos que se pueden utilizar en todas las clases de los componentes
 * para mostrar datos en consola.
 *
 * ES6 Module
 *
 * @category   Nucleus
 * @package    Helpers
 * @author     Miguel Valencia @mavo <nova_mig@hotmail.com>
 */

/**
 * prueba
 */
function dt(...$args) {
    console.trace(...$args);
}

/**
 * prueba
 */
function de(...$args) {
    console.error(...$args);
}

/**
 * prueba
 */
function dd(...$args) {
    console.log(...$args);
}

module.exports = {
    dt,
    de,
    dd
};