// Importa todos los componentes de Nucleus aquí
import CopyToClipboard from './CopyToClipboard/init';
import SwapContent from './SwapContent/init';

// Exporta los componentes como parte del objeto 'nucleus'
export default {
    copyToClipboard: CopyToClipboard,
    swapContent: SwapContent
    // Agrega aquí otros módulos de Nucleus que quieras exponer
};
