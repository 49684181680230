/**
 * Clase CopyToClipboard para copiar el contenido de un elemento al portapapeles.
 *
 * ES6 Module
 *
 * @category   Nucleus
 * @package    Mixins
 * @author     Miguel Valencia @mavo <nova_mig@hotmail.com>
 */
class CopyToClipboard {
    /**
     * Constructor de la clase CopyToClipboard.
     *
     * @constructor
     * @param {string|null} selector - Selector del botón para inicialización manual.
     *
     * @returns {void}
     */
    constructor(selector = null) {
        // Crea un elemento temporal para copiar el contenido....
        this.tempInput = document.createElement('input');
        // ... de tipo texto, para que no se muestre el teclado numérico en móviles
        this.tempInput.type = 'text';

        // Si se ha pasado un selector, se inicializa el botón manualmente...
        if (selector) {
            // ... y se guarda el botón en la propiedad button
            this.button = document.querySelector(selector);

            // Verifica que el botón exista...
            if (!this.button) {
                // ... y muestra un error en la consola si no existe...
                console.error(`Button with selector "${selector}" not found.`);
                // ... y sale de la función
                return;
            }

            // Si el botón existe, se inicializa
            this.element = null;
        } else {
            // ... si no se ha pasado un selector, se inicializan todos los botones
            this.buttons = document.querySelectorAll('[data-nc-trigger="copy"]');
        }

        // Inicializa los botones
        this.initButtons();
    }

    /**
     * Inicializa los botones con atributo data-nc-trigger="copy".
     *
     * @returns {void}
     */
    initButtons() {
        // Si se ha pasado un selector...
        if (this.button) {
            // ... se inicializa el botón con ese selector
            this.initButton(this.button);
        } else {
            // ... si no se ha pasado un selector, se inicializan todos los botones
            this.buttons.forEach(button => this.initButton(button));
        }
    }

    /**
     * Inicializa un botón para copiar el contenido al hacer clic.
     *
     * @param {HTMLButtonElement} button - Botón para inicializar.
     *
     * @returns {void}
     */
    initButton(button) {
        // Al hacer clic en el botón...
        button.addEventListener('click', () => {
            // ... se busca el elemento a copiar...
            this.element = document.querySelector(button.dataset.ncTarget);

            // ... verifica que el elemento exista...
            if (!this.element) {
                // ... muestra un error en la consola si no existe...
                console.error(`Element with selector "${button.dataset.ncTarget}" not found.`);
                // ... y sale de la función
                return;
            }

            // ... Copia el contenido del elemento al portapapeles
            this.copy();
            // ... obtiene el texto original del botón...
            const ORIGINAL_TEXT = button.innerText;
            // ... cambia el texto del botón a "Copiado"...
            button.innerText = 'Copiado';
            // ... elimina las clases de color primario...
            button.classList.remove('btn-outline-primary');
            // ... y agrega las clases de color secundario
            button.classList.add('btn-outline-secondary');
            // Después de 2 segundos...
            setTimeout(() => {
                button.innerText = ORIGINAL_TEXT;                  // ... regresa el texto original al botón...
                button.classList.remove('btn-outline-secondary');  // ... elimina las clases de color secundario...
                button.classList.add   ('btn-outline-primary');    // ... y agrega las clases de color primario
            }, 2000);
        });
    }

    /**
     * Copia el contenido del elemento al portapapeles.
     */
    copy() {
        // Verifica que el elemento exista...
        if (!this.element) {
            // ... muestra un error en la consola si no existe...
            console.error('Element to copy not found.');
            // ... y sale de la función
            return;
        }

        // Obtiene el texto del elemento a copiar y lo asigna al input temporal...
        this.tempInput.value = this.element.textContent.trim();
        // ... agrega el input temporal al DOM...
        document.body.appendChild(this.tempInput);
        // ... selecciona el texto del input temporal...
        this.tempInput.select();

        // Intenta copiar el texto al portapapeles
        try {
            // Ejecuta el comando de copiar...
            const SUCCESS = document.execCommand('copy');

            // ... Verifica la respuesta del comando de copiar esta vacía...
            if (!SUCCESS) {
                // ... muestra un mensaje en la consola si se ha copiado correctamente...
                console.error('Copy to clipboard failed.');
            }
        // ... si el comando de copiar falla...
        } catch (err) {
            // ... muestra un mensaje en la consola si se ha copiado correctamente...
            console.error('Copy to clipboard failed:', err);
        }

        // Elimina el input temporal del DOM
        document.body.removeChild(this.tempInput);
    }
}

// Inicializar automáticamente los botones con data-nc-trigger="copy"
export default CopyToClipboard;
