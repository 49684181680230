// Importar la clase SwapContent
import SwapContent from './SwapContent';

// agrega un listener al contenido cuando se carga el DOM...
document.addEventListener('DOMContentLoaded', function() {
    // ... e inicializa los botones
    const swapInstance = new SwapContent();
});

export default SwapContent;
