/**
 * Description: Inicia todos los elementos con mtm (ManyToMany)
 *
 * ES6 Module
 *
 * @category   Nucleus
 * @package    Helpers
 * @author     Miguel Valencia @mavo <nova_mig@hotmail.com>
 */

const nuc                = require('../helpers/helpers.js');  // Importa todas las funciones helpers
const ManyToManySelector = require('./mtm-options.js');       // Importa la clase ManyToManySelector

// Inicializa automáticamente la biblioteca buscando elementos con data-nc-trigger='mtm-selector'
document.addEventListener('DOMContentLoaded', () => {
    // Inicializa el componente ManyToManySelector
    new ManyToManySelector();
});

// Exponer ManyToManySelector en el ámbito global (opcional, únicamente para acceso desde el HTML)
window.ManyToManySelector = ManyToManySelector;
